import { ListGroup, ListItem } from "@gisce/oficina-virtual-components"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom";
import Settings from "../../../settings";
import { useSelector } from "react-redux";
import {
  Feedback,
  PersonAdd,
  VpnKey,
  PermIdentity,
} from "@mui/icons-material";
import { GroupedSideBar } from "./GroupedSideBar";
import { RootState } from "@/store";
import { UngroupedSideBar } from "./UngroupedSideBar";
import { contraction_url, isContractationEnabled } from "@/constants/i18n";
import DinamicServerNavigation from "@/components/ServerElements/DinamicServerNavigation";
import FeatureFlag from "@/components/FeatureFlag/FeatureFlag";

export const MainListItems = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

  const navigateChangePassword = () => {
    if (Settings.features?.oidc) {
      window.location.href = Settings.oidc.changePasswordUrl;
    } else {
      navigate(t('common:url.changePassword'))
    }
  }

  const ProfileListGroup = <ListGroup
    icon={<PermIdentity />}
    titleLabel={t('common:text.altHome_my_profile')}
    startOpen={location.pathname == t('common:url.changePassword')}
  >
    <ListItem
      icon={<VpnKey />}
      label={t('common:text.change_password_view_title')}
      onClick={() => navigateChangePassword()}
      selected={location.pathname == t('common:url.changePassword')}
    />
  </ListGroup>

  return <>
    {Settings.sidebar?.groups ?
      <GroupedSideBar />
    :
      <UngroupedSideBar />
    }
    {isAuthenticated &&
      <>
        {ProfileListGroup}
        {Settings.features?.reclamATCMenu && <ListItem
          icon={<Feedback />}
          label={t('common:text.reclamations_title')}
          onClick={() => navigate(t('common:url.reclamations'))}
          selected={location.pathname == t('common:url.reclamations')}
        />}
        {Settings.features?.contact && (
          <FeatureFlag flagName="crm.case">
            <ListItem
              icon={<PersonAdd />}
              label={t('common:text.contact_title')}
              onClick={() => navigate(t('common:url.contact'))}
              selected={location.pathname == t('common:url.contact')}
            />
          </FeatureFlag>
        ) }
      </>
    }
  </>
}